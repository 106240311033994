// feature
import Link from 'gatsby-link'
import styled from 'styled-components'
import { breakpointUp } from '@/utils/breakpoints'
import { metaData } from '@/feature/staticData'
import { colorConfig, mixins } from '@/utils/styleConfig'
import { Seo, LayoutContainer } from '@/utils/globalComponents'

// components
import Layout from '@/components/Layout'
import { HeadingLevel1 } from '@/components/HeadingLevel1'

const IndexPage = props => (
  <Layout>
    <Seo page="sitemap" />
    <SiteMap>
      <LayoutContainer>
        <HeadingLevel1 note="site map">サイトマップ</HeadingLevel1>
        <SiteMapListContainer>
          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.top.url}>トップページ</StyledLink>
            </SiteMapHeading>
          </li>
          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.company.url}>{metaData.company.title}</StyledLink>
            </SiteMapHeading>
          </li>
          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.it.url}>{metaData.it.title}</StyledLink>
            </SiteMapHeading>
          </li>
          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.display.url}>{metaData.display.title}</StyledLink>
            </SiteMapHeading>
          </li>
          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.case.url}>{metaData.case.title}</StyledLink>
            </SiteMapHeading>
          </li>
          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.recruit.url}>{metaData.recruit.title}</StyledLink>
            </SiteMapHeading>
          </li>
          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.yougo.url}>{metaData.yougo.title}</StyledLink>
            </SiteMapHeading>
          </li>

          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.contact.url}>{metaData.contact.title}</StyledLink>
            </SiteMapHeading>
          </li>
          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.privacy.url}>{metaData.privacy.title}</StyledLink>
            </SiteMapHeading>
          </li>
          <li>
            <SiteMapHeading>
              <StyledLink to={metaData.sitemap.url}>{metaData.sitemap.title}</StyledLink>
            </SiteMapHeading>
          </li>
        </SiteMapListContainer>
      </LayoutContainer>
    </SiteMap>
  </Layout>
)

const SiteMap = styled.div`
  position: relative;
`

const SiteMapHeading = styled.h2``

const SiteMapListContainer = styled.ul`
  margin-top: 8rem;

  ${breakpointUp('md')} {
    column-count: 2;
  }

  > li {
    break-inside: avoid;

    + li {
      margin-top: 2rem;
    }
  }
`

const StyledLink = styled(Link)`
  ${mixins.hoverText};
`

const SiteMapLink = styled.span`
  display: block;
  padding: 0.8rem;
  border-bottom: 1px dotted ${colorConfig.borderColor};
  width: 100%;
  height: 100%;
  color: ${colorConfig.themeColor};
  font-size: 1.4rem;
`

export default IndexPage
